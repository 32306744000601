/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import { jsx } from "theme-ui";

import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
  return (
    <Wrapper>
      <StaticImage
        src="../images/tianguis-kolping.jpg"
        alt="Agente de Seguros"
        className="hero-img"
        placeholder="tracedSVG"
        layout="fullWidth"
      ></StaticImage>
      <div className="hero-container">
        <div className="hero-text">
          <h1 sx={{ color: "primary", fontFamily: "heading" }}>
            Tatik Adelino
          </h1>
          <h4>Productos Chiapanecos</h4>
        </div>
      </div>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.div`
  height: 40vh;
  position: relative;
  margin-bottom: 2rem;

  .hero-img {
    height: 100%;
    border-radius: 0.25rem;
  }

  .hero-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0.25rem;
  }

  .hero-text {
    text-align: center;
  }
`;
