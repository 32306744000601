import React from "react";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import { graphql } from "gatsby";

import MainPage from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/main/MainPage";
import strings from "../@gatsbystorefront/gatsby-theme-storefront-shopify/templates/main/strings.json";

const { pageTitleTemplate } = strings;

const index = (props) => {
  const { storeName, storeDescription } =
    props.data.store.siteMetadata.gatsbyStorefrontConfig;

  return (
    <>
      <Seo title={storeName} description={storeDescription} />
      <MainPage {...props} />
      <Hero />
    </>
  );
};

export default index;

export const mainPageQuery = graphql`
  query MainPageQuery1(
    $handles: [String]
    $featuredCollectionsHandles: [String]
  ) {
    collections: allShopifyCollection(filter: { handle: { in: $handles } }) {
      nodes {
        handle
        title
        description
        fields {
          shopifyThemePath
        }
        image {
          src
          localFile {
            childImageSharp {
              resize(base64: true) {
                src
                width
                height
                aspectRatio
              }
            }
          }
        }
      }
    }

    feauturedCollections: allShopifyCollection(
      filter: { handle: { in: $featuredCollectionsHandles } }
    ) {
      nodes {
        title
        handle
        description
        products {
          id
          shopifyId
          title
          tags
          fields {
            shopifyThemePath
            firstImage {
              altText
              originalSrc
              localFile {
                childImageSharp {
                  resize(base64: true) {
                    src
                    width
                    height
                    aspectRatio
                  }
                }
              }
            }
          }
          availableForSale
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          variants {
            shopifyId
            availableForSale
            compareAtPrice
            price
          }
        }
      }
    }

    products: allShopifyProduct(filter: { handle: { in: $handles } }) {
      nodes {
        title
        description
        handle
        fields {
          shopifyThemePath
          firstImage {
            altText
            originalSrc
            localFile {
              childImageSharp {
                resize(base64: true) {
                  src
                  width
                  height
                  aspectRatio
                }
              }
            }
          }
          descriptionSections {
            id
          }
          shortDescription
        }
      }
    }

    store: site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
          storeDescription
        }
      }
    }
  }
`;
